var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FilterCollection from "~/components/collection-manage/all-case/filter-collection.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace, Getter } from "vuex-class";
import { Watch } from "vue-property-decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { StatusConfigService } from "~/services/manage-service/status-config.service";
import NumberRange from "~/components/common/number-range.vue";
import { CollectionData } from "~/config/types/collection-data";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
import { StorageService } from "~/utils/storage.service";
import FollowAllQueryBatch from "~/components/case-manage/case-adjustment/follow-all-query-batch.vue";
var collectionManageModule = namespace("collection-manage");
//@Auth(110)
var CollectionCase = /** @class */ (function (_super) {
    __extends(CollectionCase, _super);
    function CollectionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.switchCaseType = "1";
        _this.money = "";
        _this.loading = {
            state: false,
        };
        _this.deptIds = [];
        _this.currentCase = {};
        _this.collectionCount = {};
        _this.queryParamsModel = {
            principalId: "",
            batchNumber: "",
            personalName: "",
            certificateNo: "",
            caseNumber: "",
            cardNo: "",
            phone: "",
            stage: "",
            collectStatus: "",
            contactStatus: "",
            employerName: "",
            address: "",
            overdueAmtTotal: {
                min: "",
                max: "",
            },
            leftAmt: {
                min: "",
                max: "",
            },
            payStatus: "",
            isAssist: "",
            delegationDate: [],
            comment: "",
            collectorName: "",
            departCode: "",
            retireCaseDays: "",
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
            telRecordCount: "",
            city: "",
            endCaseDate: "",
            caseFiller: "",
            caseType: "",
        };
        _this.selectionList = [];
        _this.dataSet = [];
        _this.handSet = [];
        _this.buttonStatus = [];
        _this.collectionStatus = [];
        _this.collectorDate = [];
        _this.dialog = {
            detail: false,
            phone: false
        };
        _this.contact = [];
        _this.collect = [];
        _this.rules = {
            certificateNo: [
                {
                    min: 4,
                    message: "请输入至少4位的身份证号",
                    trigger: "blur",
                },
            ],
            phone: [
                {
                    min: 4,
                    message: "请输入至少4位的手机号",
                    trigger: "blur",
                },
            ],
        };
        return _this;
    }
    CollectionCase.prototype.onRouteChange = function (val) {
        if (!val)
            return;
        this.handleResetForm();
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
        this.getConfigByPrin();
    };
    CollectionCase.prototype.mounted = function () {
        var _this = this;
        this.statusConfigService.getStatusConfig(this.queryParamsModel.principalId, this.loading).subscribe(function (data) {
            _this.contact = data.contactStatus;
            _this.collect = data.collectStatus;
        });
        this.refreshData();
        var httpRequest = new XMLHttpRequest(); //第一步：建立所需的对象
        httpRequest.open('GET', 'https://pgft.liansdk.com/trusteeship/bj/getagent?username=' + StorageService.getItem("userInfoStage").username, true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send(); //
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json = httpRequest.responseText; //获取到json字符串，还需解析
                if (JSON.parse(json).code == 1) {
                    localStorage.setItem('mytask', JSON.parse(json).data.task);
                    localStorage.setItem('myagent', JSON.parse(json).data.agent);
                }
                console.log(localStorage.getItem('mytask'));
            }
        };
    };
    CollectionCase.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
    };
    Object.defineProperty(CollectionCase.prototype, "payStatusEnum", {
        get: function () {
            var payEnum = this.$dict.getDictData("PayStatus").map(function (x) {
                return x;
            });
            return payEnum.filter(function (x) { return x.code !== "SETTLT"; });
        },
        enumerable: false,
        configurable: true
    });
    CollectionCase.prototype.handleResetForm = function () {
        this.queryParamsModel.batchNumber = "";
        this.queryParamsModel.personalName = "";
        this.queryParamsModel.certificateNo = "";
        this.queryParamsModel.caseNumber = "";
        this.queryParamsModel.cardNo = "";
        this.queryParamsModel.phone = "";
        this.queryParamsModel.stage = "";
        this.queryParamsModel.collectStatus = "";
        this.queryParamsModel.employerName = "";
        this.queryParamsModel.address = "";
        this.queryParamsModel.payStatus = "";
        this.queryParamsModel.isAssist = "";
        this.queryParamsModel.comment = "";
        this.queryParamsModel.collectorName = "";
        this.queryParamsModel.departCode = "";
        this.queryParamsModel.retireCaseDays = "";
        this.queryParamsModel.telRecordCount = "";
        this.queryParamsModel.city = "";
        this.queryParamsModel.endCaseDate = "";
        this.queryParamsModel.caseFiller = "";
        this.queryParamsModel.overdueAmtTotal = [];
        this.queryParamsModel.leftAmt = [];
        this.queryParamsModel.overduePeriods = [];
        this.queryParamsModel.overdueDays = [];
        this.queryParamsModel.delegationDate = [];
        this.deptIds = [];
    };
    CollectionCase.prototype.getConfigByPrin = function () {
        var _this = this;
        this.loading.state = true;
        var param = "COLLECT_STATUS";
        this.statusConfigService.findAllConfigNoPage(param, this.loading).subscribe(function (data) {
            _this.collectionStatus = data;
        });
    };
    CollectionCase.prototype.refreshData = function () {
        var _this = this;
        this.caseCollectorService.findAllCase(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
            for (var j = 0; j < _this.dataSet.length; j++) {
                var fs = (_this.pageService.pageIndex - 1) * _this.pageService.pageSize + j + 1;
                if (_this.queryParamsModel.principalId == "7f000101-895a-1ce2-818a-49a9bd8200cd") {
                    _this.dataSet[j].cardNo = "案号(0835)056调" + fs.toString().padStart(5, "0") + "号";
                }
                else if (_this.queryParamsModel.principalId == "7f000101-895a-1ce2-818a-49a99ca20091") {
                    _this.dataSet[j].cardNo = "案号(0835)057调" + fs.toString().padStart(5, "0") + "号";
                }
                else {
                    _this.dataSet[j].cardNo = "案号(0835)058调" + fs.toString().padStart(5, "0") + "号";
                }
            }
            console.log("dfdsfsdfsdfsdaaaaaaaaa");
            console.log(_this.queryParamsModel.principalId);
            console.log(_this.dataSet);
            // 刷新筛选列表框
            delete _this.queryParamsModel.collectionRecordCount;
        });
    };
    CollectionCase.prototype.cellStyle = function (_a) {
        var _this = this;
        var row = _a.row;
        if (this.collectionStatus && row.collectStatus) {
            var statusArr = row.collectStatus.split(",");
            var findArr_1 = [];
            statusArr.forEach(function (x) {
                var a = _this.collectionStatus.find(function (status) { return x === status.name; });
                if (a) {
                    findArr_1.push(a);
                }
            });
            if (findArr_1.length > 0) {
                row.color = findArr_1.reduce(function (a, b) {
                    return b.sort > a.sort ? b : a;
                }).color;
            }
        }
        if (!row.color)
            return;
        return {
            color: row.color + " !important",
        };
    };
    CollectionCase.prototype.onFilterSearch = function (val) {
        // 业务处理
        this.queryParamsModel.caseFiller = val.caseFiller;
        this.refreshData();
    };
    /**
     * 机构改变
     */
    CollectionCase.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.queryParamsModel.departCode = valueArray[valueArray.length - 1];
    };
    CollectionCase.prototype.onBatchNumberClick = function (currentCollection) {
        var otherData = {
            sort: this.sortService.clone(),
            caseType: currentCollection.caseType,
        };
        this.queryParamsModel.casePool = "INNER"; //在调案件池
        var data = new CollectionData(this.queryParamsModel.principalId, currentCollection.id, this.queryParamsModel, otherData);
        this.openExecutionPage(data);
    };
    CollectionCase.prototype.onBatchNumberClick2 = function (currentCollection) {
        var otherData = {
            sort: this.sortService.clone(),
            caseType: 'TEL_CASE',
        };
        this.queryParamsModel.casePool = "INNER"; //在调案件池
        var data = new CollectionData(this.queryParamsModel.principalId, currentCollection, this.queryParamsModel, otherData);
        this.openExecutionPage(data);
    };
    CollectionCase.prototype.onPhoneClick = function () {
        console.log('phoneclick');
        this.dialog.phone = true;
    };
    CollectionCase.prototype.refreshSelect = function (val) {
        this.selectionList = val;
        console.log(this.selectionList);
    };
    CollectionCase.prototype.removephone = function (val) {
        for (var i = 0; i < this.selectionList.length; i++) {
            if (this.selectionList[i].id == val.id) {
                this.selectionList.splice(i, 1);
            }
        }
    };
    CollectionCase.prototype.stopTask = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', 'https://legal.liansdk.com/auto_call_api/getTaskAction.php?username=admin&password=Gtkjya2023&company_code=yafz2&task_id=' + localStorage.getItem('currtask') + '&action=del&group_ids=161', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json4 = httpRequest.responseText;
                console.log(json4);
                that.dialog.phone = false;
            }
        };
    };
    CollectionCase.prototype.gotoPhoneCase = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', 'https://pgft.liansdk.com/trusteeship/yalt/getconnect?dst=' + localStorage.getItem('myagent'), true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json4 = httpRequest.responseText;
                console.log(json4);
                if (1 == JSON.parse(json4).code) {
                    console.log('接通的电话：' + JSON.parse(json4).data.src);
                    var phonenum = JSON.parse(json4).data.src;
                    if (phonenum.length < 10) {
                        phonenum = JSON.parse(json4).data.dst;
                    }
                    var httpRequest2 = new XMLHttpRequest();
                    httpRequest2.open('GET', 'https://pgft.liansdk.com/trusteeship/yalt/getcase?phone=' + phonenum, true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
                    httpRequest2.send();
                    httpRequest2.onreadystatechange = function () {
                        if (httpRequest2.readyState == 4 && httpRequest2.status == 200) {
                            var json5 = httpRequest2.responseText;
                            if (1 == JSON.parse(json5).code) {
                                console.log('gotocase:' + JSON.parse(json5).data.id);
                                that.onBatchNumberClick2(JSON.parse(json5).data.id);
                                return;
                            }
                            else {
                                alert('没有接通');
                            }
                        }
                    };
                }
                //              for(let j=0;j<that.selectionList.length;j++){
                //                console.log('列表的电话：' + that.selectionList[j].phone)
                //
                //                if(that.selectionList[j].phone == JSON.parse(json4).data.src){
                //                    console.log('跳转实行')
                //                    that.onBatchNumberClick(that.selectionList[j])
                //                    return
                //                }
                //              }
            }
            else {
                //alert('没有接通')
            }
        };
    };
    CollectionCase.prototype.startTask = function () {
        var that = this;
        var httpRequest3 = new XMLHttpRequest();
        httpRequest3.open('GET', 'https://legal.liansdk.com/auto_call_api/getTaskAction.php?username=admin&password=Gtkjya2023&company_code=yafz2&task_id=' + localStorage.getItem('currtask') + '&action=del&group_ids=161', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest3.send();
        httpRequest3.onreadystatechange = function () {
            if (httpRequest3.readyState == 4 && httpRequest3.status == 200) {
                that.createTask();
            }
        };
        var httpRequest4 = new XMLHttpRequest();
        httpRequest4.open('GET', 'https://pgft.liansdk.com/trusteeship/yalt/saveaction?agent=' + localStorage.getItem('myagent') + '&action=benren', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest4.send();
    };
    CollectionCase.prototype.createTask = function () {
        var that = this;
        var httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', 'https://legal.liansdk.com/auto_call_api/getAiTaskAdd.php?username=admin&password=Gtkjya2023&company_code=yafz2&template_id=' + localStorage.getItem('mytask') + '&task_name=task_name&task_override=1.5', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
        httpRequest.send();
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                var json2 = httpRequest.responseText; //获取到json字符串，还需解析
                if (200 == JSON.parse(json2).status) {
                    var taskid = JSON.parse(json2).msg;
                    console.log(taskid);
                    localStorage.setItem('currtask', taskid);
                    var httpRequest2 = new XMLHttpRequest();
                    var formData = new FormData();
                    formData.append('username', 'admin');
                    formData.append('password', 'Gtkjya2023');
                    formData.append('company_code', 'yafz2');
                    formData.append('task_id', taskid);
                    var phonelist = '';
                    for (var i = 0; i < that.selectionList.length; i++) {
                        phonelist = phonelist + that.selectionList[i].phone + ',';
                    }
                    formData.append('phones', phonelist);
                    httpRequest2.open('POST', 'https://legal.liansdk.com/auto_call_api/postTaskPhones.php', true);
                    httpRequest2.send(formData);
                    httpRequest2.onreadystatechange = function () {
                        if (httpRequest2.readyState == 4 && httpRequest2.status == 200) {
                            var json3 = httpRequest2.responseText;
                            console.log(json3);
                            if (200 == JSON.parse(json3).status) {
                                var httpRequest4 = new XMLHttpRequest();
                                httpRequest4.open('GET', 'https://legal.liansdk.com/auto_call_api/getTaskAction.php?username=admin&password=Gtkjya2023&company_code=yafz2&task_id=' + taskid + '&action=run&group_ids=161', true); //第二步：打开连接  将请求参数写在url中  ps:"./Ptest.php?name=test&nameone=testone"
                                httpRequest4.send();
                                httpRequest4.onreadystatechange = function () {
                                    if (httpRequest4.readyState == 4 && httpRequest4.status == 200) {
                                        var json4 = httpRequest4.responseText;
                                        console.log(json4);
                                        alert('呼叫中，请等待接听');
                                    }
                                };
                            }
                        }
                    };
                }
            }
        };
    };
    __decorate([
        Dependencies(PageService)
    ], CollectionCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CollectionCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(StatusConfigService)
    ], CollectionCase.prototype, "statusConfigService", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], CollectionCase.prototype, "caseCollectorService", void 0);
    __decorate([
        collectionManageModule.Action
    ], CollectionCase.prototype, "openExecutionPage", void 0);
    __decorate([
        State
    ], CollectionCase.prototype, "principalList", void 0);
    __decorate([
        State
    ], CollectionCase.prototype, "collectorList", void 0);
    __decorate([
        Getter
    ], CollectionCase.prototype, "departmentData", void 0);
    __decorate([
        Watch("$route", {
            immediate: true,
        })
    ], CollectionCase.prototype, "onRouteChange", null);
    CollectionCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowAllQueryBatch: FollowAllQueryBatch,
                DataForm: DataForm,
                DataBox: DataBox,
                FilterCollection: FilterCollection,
                FollowAllQuery: FollowAllQuery,
                NumberRange: NumberRange,
            },
        })
    ], CollectionCase);
    return CollectionCase;
}(Vue));
export default CollectionCase;
